import React, { Component } from "react";

class About extends Component {
  render = () => {
    return (
      <div>
        {" "}
        <h1>About Page</h1>
      </div>
    );
  };
}

export default About;
